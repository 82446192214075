<template>
	<v-sheet id="content-page">
		<slot name="content"></slot>
		<content-footer></content-footer>
	</v-sheet>
</template>

<style lang="less">
@import "../../assets/css/variables.less";

#content-page blockquote {
	color: @nhsblue;
	background: #E3EAF1;
	padding: 12px 16px;
    line-height: 1.5em;
    font-size: 16px;
    border-radius: 5px;
}
</style>
<script>
import ContentFooter from "@c/ui/ContentFooter";
export default {
	name: "ContentPage",
	components: {
		ContentFooter,
	},
};
</script>
