<template>
	<v-navigation-drawer
		v-model="drawer"
		:mini-variant="mini"
		touchless
		permanent
		app
		right
		style="z-index: 101"
		width="350px"
	>
		<template v-if="!mini">
			<v-card flat>
				<v-card-text>
					<slot name="prepend"></slot>
					<v-text-field label="Search" v-model="search"></v-text-field>
				</v-card-text>
			</v-card>
			<v-divider></v-divider>
			<div class="pa-2 mb-0" v-if="search.length">
				<p v-if="filteredItems.length">
					Results found in:  
				</p>
				<p v-else>
					No results found
				</p>
			</div>
			<v-list dense>
				<v-list-item
					v-for="(item, i) in filteredItems"
					:key="i"
					:class="{active: item.ids.includes(active)}"
					@click="scrollTo(item.ids[0])"
				>
					<v-list-item-content>
						<v-list-item-title> {{ item.title }} </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<slot name="append"></slot>
			</v-list>
		</template>
		<template v-slot:append>
			<v-divider class="mt-1 mb-3"></v-divider>
			<v-list dense class="pl-1">
				<nav-item
					@click="mini = !mini"
					:text="$t('buttons.collapse')"
					icon="mdi-menu"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<style lang="less" scoped>
	.v-list-item__title {
		overflow: auto;
		white-space: normal;
	}
	.v-list-item.active {
		background-color: #4caf50 !important;
		.v-list-item__title {
			color: white !important;
		}
	}
</style>
<script>
	import NavItem from "@c/navigation/Item";

	export default {
		name: "ContentContetns",
		props: {
			value: {type: Array, default: () => []},
			active: {type: String}
		},
		components: {
			NavItem
		},
		data: () => {
			return {
				drawer: true,
				mini: false,
				search: ""
			};
		},
		computed: {
			filteredItems() {
				const self = this;
				return self.value.filter((item) => {
					return (
						self.isInTitle(item) || self.isInBody(item)
					);
				});
			}, 
			content() {
				return this.$store.state.content.data;
			}
		},
		methods: {
			isInTitle(item){
				return item.title &&
						item.title.toLowerCase().includes(this.search.toLowerCase())
			},
			isInBody(item){
				const self = this;
				let content = item.ids.map( id => self.content[id] );
				let match = content.find( i => 	i.html.includes(self.search) );
				return match ? true : false;
			},
			scrollTo(id) {
				let pos = document.getElementById(id).offsetTop - 20;
				window.scrollTo({
					top: pos,
					behavior: "smooth"
				});
			}
		},
		inject: ["isMobile"],
		created() {
			if (this.isMobile) {
				this.mini = true;
			}
		}
	};
</script>
