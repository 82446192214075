<template>
    <v-alert color="primary" text>
        We hope the information on this page is useful to you. <br/> If you have any comments, improvement ideas or topics you'd like to see included on this page please let us know by using the live chat function or via the contact us page. 
    </v-alert>
</template>

<script>
export default {
    name: "ContentFooter"
}
</script>