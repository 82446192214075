<template>
	<v-sheet class="content-list">
		<template v-for="(item, i) in value">
			<v-card flat v-if="item.type == 'content'" :key="i" v-bind:id="item.id">
				<template v-if="items[item.id]">
					<v-card-title>
						{{ items[item.id].title }}
					</v-card-title>
					<v-card-text>
						<div
							v-intersect="(a,b,c) => handleIntersect(item.id, c)"
							v-html="items[item.id].html"
						></div>
					</v-card-text>
				</template>
			</v-card>
			<template v-if="item.type == 'slot'">
				<slot :name="item.name"></slot>
			</template>
		</template>
	</v-sheet>
</template>
<style lang="less">
	.content-list h2 {
		font-weight: 400;
		font-size: 1.2em;
	}
	.content-list h3 {
		font-size: 1em;
	}
	.v-card__title {
		word-break: normal;
	}
</style>
<script>
	export default {
		name: "ContentList",
		props: {
			value: {type: Array, default: () => []}
		},
		data: () => {
			return {
			};
		},
		computed: {
			items() {
				return this.$store.state.content.data;
			}
		},
		methods: {
			handleIntersect(id, isVisible) {
				if( isVisible ){
					this.$emit('focused', id );
				}
			}
		},
		created() {
			let ids = this.value.filter((c) => c.type == "content").map((c) => c.id);
			this.$store.dispatch("content/fetchByIds", ids);
		}
	};
</script>
